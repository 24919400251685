<template>
  <PageContainer v-if="userData">
    <PageTitle :breadcrumb="{name: 'settings'}" class="w-full lg:w-main-container">
      {{ trans('BUTTON:BackToSettings') }}
    </PageTitle>
    <t-card class="w-full lg:w-main-container">
      <div class="text-base font-semibold text-black mb-3 lg:mb-6">
        {{ trans('LABEL:VerificationFormTitle') }}
      </div>
      <Hint variant="Info" :large="true" class="flex flex-col">
        <div>
          {{ trans('LABEL:VerificationFormCommentFields') }}
        </div>
        <div>
          {{ trans('LABEL:VerificationFormCommentDocuments') }}
        </div>
        <div>
          {{ trans('LABEL:VerificationFormCommentPrivacy') }}
        </div>
      </Hint>
      <div v-if="render">
        <VeriffForm />
      </div>
    </t-card>
  </PageContainer>
</template>

<script>
import PageContainer from "@/layouts/components/pages/PageContainer";
import PageTitle from "@/layouts/components/pages/PageTitle";
import Hint from "@/views/content/components/Hint";

import {mapGetters} from "vuex";
import VeriffForm from "@/views/content/verification/components/VeriffForm";

export default {
  name: "VerificationView",
  components: {Hint, VeriffForm, PageTitle, PageContainer},
	data() {
		return {
			render: false,
		}
	},
  computed: {
    ...mapGetters('account', ['userData', 'authDone']),
    ...mapGetters('verification', ['currentVerification'])
  },
	watch: {
		currentVerification: {
			immediate: true,
			handler(val) {
				if(val) {
					this.render = true;
				}
			}
		}
	}

}
</script>

